import * as React from "react"
import Section01Home from "../components/home/Section01"
import Section02Home from "../components/home/Section02"
import Section03Home from "../components/home/Section03"
import Section04Home from "../components/home/Section04"
import Section05Home from "../components/home/Section05"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    {/* <div className="hidden lg:flex">
    <ChatComponents />
    </div> */}
    <SEO
     title= {"Base de datos de huella balística acredita por ANAB"}
     description={"Registre casquillo, bala y fragmentos ✔️ Todo en un solo equipo obteniendo una representación 3D de alta definición reduciendo el número de no concluyentes"}
    
      />
    <Section01Home />
    <Section02Home />
    <Section03Home />
    <Section04Home />
    <Section05Home />
  </Layout>
)

export default IndexPage
