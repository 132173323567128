import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

//Base Web

const Section03Home: React.FC = () => {
    return (
        <section className="container flex flex-col justify-items-center max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8 font-body text-gray">
            <h2 className="self-center text-center md:max-w-screen-lg text-heading-2 pt-12 md:pt-32 lg:pt-20 2xl:pt-28">Obtén los mejores resultados para tu organización</h2 >
            <div className="grid grid-cols-1 md:grid-cols-3 pt-20">
                <div className="text-center justify-center mx-4 lg:mx-5">
                    <StaticImage
                        alt="Evofinder DAS"
                        src="../../images/evofinder-equipo-das.jpg"
                        quality={70}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: 'scale-down', height: "168px" }}
                        width={310.89}

                    />
                    <h3 className="text-heading-5 lg:text-heading-4  pt-8">MÁS DATOS  RECOLECTADOS</h3>
                    <p className="text-left pt-8">
                        Evofinder® DAS, es un escáner de huella balística, el cual genera una imagen 3D de la muestra, facilitando el análisis sin la necesidad de maniobrar la muestra física, haciendo más práctico el manejo de la imagen y observando con detalle características como el rallado del cuerpo o la base del casquillo, reduciendo la posibilidad de un no concluyente.
                    </p>
                </div>
                <div className="text-center justify-center mx-4 lg:mx-5 pt-8 md:pt-0">
                    <StaticImage
                        alt="Evofinder observacion"
                        src="../../images/evofinder_observacion.png"
                        quality={70}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: 'contain', height: "168px" }}
                        width={310.48}

                    />
                    <h3 className="text-heading-5 lg:text-heading-4 pt-8">MEJORA EN OBSERVACIÓN </h3>
                    <p className="text-left pt-8">
                    Evofinder® VCM (Virtual Comparison Microscope). Microscopio de Comparación Virtual, consiste en el manejo u operación activa de la imagen escaneada en 3D, donde nos permite observar características tanto generales como particulares o individuales sin necesidad de maniobrar la muestra física, con mayor detalle visual que un LCM (Microscopio de comparación de luz).                    </p>

                </div>
                <div className="text-center justify-center mx-4 lg:mx-5 pt-8 md:pt-0">
                    <StaticImage
                        alt="Evofinder tecnologia 3d"
                        src="../../images/evofinder-3d.jpg"
                        quality={70}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: 'contain', height: "164px" }}
                        width={163}

                    />
                    <h3 className="text-heading-5 lg:text-heading-4 pt-8">TECNOLOGÍA 3D EVOFINDER®</h3>
                    <p className="text-left pt-8">
                    Alta calidad de imágenes, simplicidad de operación y algoritmos efectivos de procedimiento de búsqueda automática, aumentan la efectividad de la correlación. Evofinder permite tener intercambios interlaboratorios mediante archivos topográficos en 3D de alta resolución, con cualquier otro sistema basado en el estándar X3P, con mejor detalle visual que un LCM.
                    </p>

                </div>
            </div>
        </section>
    );
};

export default Section03Home;
