import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Section04Home: React.FC = () => {
    return (
        <section className="container   max-w-4xl 2xl:max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="font-body text-gray md:mr-2.5">
                    <h2 className="text-heading-2 -ml-0.5 py-4">Evofinder®</h2>
                    <p className="text-base">
                        Permita que uno de nuestros expertos le ayude a evaluar 
                        el impacto económico de Evofinder en su laboratorio.
                    </p>
                    <div className="inline-flex text-white mb-4 mt-6">
                        <a href="/pricing" className="inline-flex font-body text-red">
                            Más sobre las razones financieras >
                        </a>
                    </div>
                </div>
                <div className="pt-6 flex justify-center  md:ml-2.5">
                    <StaticImage
                        alt=""
                        src="../../images/costos.png"
                        quality={75}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        imgStyle={{ objectFit: 'contain',  }}

                    />
                </div>
            </div>
        </section>
    )
}

export default Section04Home
