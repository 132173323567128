import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const Section05Home: React.FC = () => {
    return (
        <section className="flex justify-center  pt-14 md:pt-32 lg:pt-20 2xl:pt-28">
            <div className="relative">
                <StaticImage
                    alt="Evofinder laboratorio"
                    src="../../images/evofinder-escritorio-2.jpg"
                    quality={70}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    imgStyle={{ objectFit: "contain" }}
                />
                <div className="lg:absolute w-full max-w-4xl 2xl:max-w-6xl lg:top-1/2 lg:left-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 transform rotate-0 bg-white text-center font-body text-gray p-8">
                    <h2 className="text-heading-4 pb-8">RESULTADOS REALES</h2>
                    <p className="text-base italic text-red">“El Sistema Evofinder como una herramienta moderna de aportación forense
                        en la creación de bases de datos de la Huella Balística, ha resultado muy
                        funcional y eficaz, para la obtención de resultados, con lo cual nos ha permitido
                        seguir trabajando como Laboratorio en Balística Forense, perteneciente a esta
                        noble Institución como es la Fiscalía General del Estado, bajo Estándares y
                        normas de calidad Internacional, todo esto con el fin único de contribuir en la
                        Procuración de Justicia, enfocado a la sociedad poblana.
                        ”</p>
                    <p className="font-medium text-sm  pt-4">LIC. GUILLERMO RAMOS AYÓN</p>
                    <p className="text-xs pt-2">EXPERTO EN BALISTICA FORENSE</p>
                    <p className="text-sm  py-2 pb-4">FISCALIA GENERAL DEL ESTADO DE PUEBLA INSTITUTO DE CIENCIAS FORENSES.</p>
                    <div className="text-center text-white my-4 py-2 text-base bg-green">
                        <a href="https://calendar.google.com/calendar/selfsched?sstoken=UU5PQWlwRjBDRC1ifGRlZmF1bHR8N2EzNmM3NmQyZTlmMWRiMzMwYmMwNGViNDg4YzBlMGM"
                            target="_blank" className="font-body ">
                            Agende una cita con un especialista de Evofinder >
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section05Home
