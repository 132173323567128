import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Section02Home: React.FC = () => {
    return (
        <section className="flex flex-col justify-items-center pt-12 md:pt-32 lg:pt-20 2xl:pt-28">
            <div className="self-center">
                <StaticImage
                    alt="Evofinder escritorio"
                    src="../../images/evofinder-escritorio.jpg"
                    quality={70}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    imgStyle={{ objectFit: "contain" }}
                />
            </div>
            <div className="container self-center max-w-4xl 2xl:max-w-6xl w-100 text-center font-body text-gray px-8">
                <h2 className="text-heading-2 pt-12">¿Qué puede hacer Evofinder® para su organización?</h2 >
                <p className="text-base pt-10 text-left">
                Evofinder® es una base de datos de huella balística, de tamaño similar a una consola de videojuegos y por ello el espacio de trabajo requerido es mínimo, en comparación a equipos de mayor tamaño que incluso pueden ocupar hasta hasta 10 a 12 m² de su espacio de trabajo.
                Evofinder puede trabajar desde un escritorio, como si fuera una PC, permitiendo visualizar imágenes 3D, facilitar el manejo de la imagen y observar con mayor detalle las características, reduciendo el número de no concluyentes.
                </p>
            </div>
        </section>
    )
}

export default Section02Home
