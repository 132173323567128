import React from "react"
import { PhoneIcon } from "@heroicons/react/outline"
import { StaticImage } from "gatsby-plugin-image"


const Section01Home: React.FC = () => {
  return (
    <section  className="container   max-w-4xl 2xl:max-w-6xl mx-auto pt-14 md:pt-32 lg:pt-20 2xl:pt-28 px-8">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="font-body text-gray md:mr-2.5">
          <h1 className="text-heading-1 -ml-0.5 2xl:py-4">Evofinder®</h1>
          <p className="text-base">
          Es una base de datos de huella balística acreditado por ANAB, de un tamaño similar a una consola de videojuegos, es capaz de capturar en un solo módulo casquillo, bala y fragmentos, con una alta resolución obteniendo una representación 3D de la muestra que mejora los resultados de correlación. Todo esto con un sistema de tecnologías patentadas y certificadas.
          </p>
          <div className="inline-flex items-stretch items-center shadow text-white h-10 mt-8 mb-6">
            <a
              href="https://calendar.google.com/calendar/selfsched?sstoken=UU5PQWlwRjBDRC1ifGRlZmF1bHR8N2EzNmM3NmQyZTlmMWRiMzMwYmMwNGViNDg4YzBlMGM"
              target="_blank"
              className="inline-flex items-center justify-center px-5 font-body text-white bg-green"
            >
              Solicitar una demostración
            </a>
          </div>
          <br></br>
          <p className="text-sm">
          Contacta a un especialista en SUMTECH y diseña tu plan de implementación personalizado. 
          </p>
          <div className="inline-flex text-white mb-4 mt-6">
            <a href="tel:528183334055" className="inline-flex font-body text-red h-6">
              <PhoneIcon className="block w-8 pr-2" aria-hidden="true" />
              +52 81 8333 4055
            </a>
          </div>
          <p className="text-xs">
            Atención disponible de lunes a viernes, de 9 a 17 hs.
            Tiempo de espera promedio para hablar con un representante: menos de
            un minuto
          </p>
        </div>
        <div className="pt-6 flex justify-center  md:ml-2.5">
          <StaticImage
            alt="Equipo Evofinder"
            src="../../images/evofinder.jpg"            
            quality={70}
            formats={["AUTO", "WEBP", "AVIF"]}
            imgStyle={{objectFit: 'contain'}}  
              
          />
        </div>
      </div>
    </section>
  )
}

export default Section01Home
